import React from 'react';
import styled from 'styled-components';
import CONST from '@beelineloans/cx-library/src/utils/constants';
import { NavOnlyTemplate } from '@beelineloans/cx-library/src/components/layout/TemplateV2';
import SEO from '@beelineloans/cx-library/src/components/SEO';
import Container from '@beelineloans/cx-library/src/components/layout/ContainerV2';
import UpdateAt from '@beelineloans/cx-library/src/components/layout/UpdateAt';
import { H2, Span, Paragraph } from '@beelineloans/cx-library/src/components/typography';
import Chrome from '@beelineloans/cx-library/src/images/icons/chrome.png';
// import Edge from '@beelineloans/cx-library/src/images/icons/edge.png';
// import Firefox from '@beelineloans/cx-library/src/images/icons/firefox.png';
import Safari from '@beelineloans/cx-library/src/images/icons/safari.png';
import { ButtonText } from '../../../../shared/GatsbyButton';

const ContainerStyled = styled(Container)`
	.container-inner {
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		@media only screen and (${props => props.theme.mediaBreakpoints.tablet}) {
			flex-direction: row;
		}
	}
`;

const Half = styled.div`
	width: 100%;

	@media only screen and (${props => props.theme.mediaBreakpoints.tablet}) {
		width: 50%;
	}
`;

const BrowserIcon = styled.img`
	height: 83px;
	width: 83px;
	display: block;
	margin: 0 auto 30px;
`;

const Grid = styled.div`
	display: grid;
	grid-template-columns: 50% 50%;
	grid-template-areas: ". .";
	grid-column-gap: 10px;
	grid-row-gap: 50px;
`;

const Cell = styled.div`
	text-align: center;
`;

const ParagraphPadded = styled(Paragraph)`
	padding-bottom: 50px;
`;

const BrowserDisclaimer = () => (
  <NavOnlyTemplate
    headerProps={{
      hideButton: true
    }}
  >
    <SEO
      path={CONST.LINKS.MAIN.LEGAL.BROWSER}
      title="Browser disclaimer"
      description="Beeline is best experienced using Chrome and Safari."
    />
    <UpdateAt date="April 2020" />
    <ContainerStyled>
      <Half>
        <H2>Browser Support</H2>
        <ParagraphPadded>
          Beeline is best experienced using the following browsers. Make sure to
          keep all your devices up to date by choosing your preferred browser
          and following the install instructions.
        </ParagraphPadded>
      </Half>
      <Half>
        <Grid>
          <Cell>
            <ButtonText to={CONST.LINKS.EXTERNAL.BROWSERS.CHROME} newWindow>
              <BrowserIcon src={Chrome} />
              <Span>Google Chrome</Span>
            </ButtonText>
          </Cell>
          {/* <Cell>
            <BrowserIcon src={Firefox} />
            <a href={CONST.LINKS.EXTERNAL.BROWSERS.FIREFOX}><Span>Mozilla Firefox</Span></a>
          </Cell> */}
          <Cell>
            <ButtonText to={CONST.LINKS.EXTERNAL.BROWSERS.SAFARI} newWindow>
              <BrowserIcon src={Safari} />
              <Span>Apple Safari</Span>
            </ButtonText>
          </Cell>
          {/* <Cell>
            <BrowserIcon src={Edge} />
            <a href={CONST.LINKS.EXTERNAL.BROWSERS.EDGE}><Span>Microsoft Edge</Span></a>
          </Cell> */}
        </Grid>
      </Half>
    </ContainerStyled>
  </NavOnlyTemplate>
);

export default BrowserDisclaimer;
